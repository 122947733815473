<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
    >
      <b-col cols="12">
        <good-table
          ref="gootable"
          :columns="columns"
          url="/v1/users/all/"
          title-table="Listado de usuarios"
          :is-active-create="$can('create', 'users')"
          :is-active-edit="$can('update', 'users')"
          :is-active-course-all="$can('read', 'users')"
          :is-active-course="$can('read', 'users')"
          :is-active-family="$can('read', 'familiares')"
          :is-active-contrato="$can('read', 'contrato')"
          :advance-search="searchTerm"
          @Crear="Crear"
          @EditOne="Edit_one"
          @Edit="Edit"
          @ActivateCourseAll="activeCourseAll"
          @activate-course-all="activeCourseUser"
          @ActivateFamily="activateFamily"
          @OpcionContrato="ContratoShow"
          @selection-changed="selectionChanged"
        />
      </b-col>
      <vue-family
        v-if="modalFamily"
        :modal-show="modalFamily"
        :info-data="infoData"
        :users="UsersData"
        @close="closeFamily"
      />
      <show-archive
        size="xl"
        ancho="750px"
        :modal-show="modalShowArchive"
        :get-file-path="getFilePath"
        @close="close2"
      />
      <course-active
        ref="CourseActive"
        :modal-show="modalShowCourse"
        :userid="UsersData"
        @result-save="$refs.gootable.loadItems()"
        @close="closeCourseActive"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCol, BOverlay,
} from 'bootstrap-vue'
import GoodTable from '@/components/table/RemoteTable.vue'
import IndexFamily from '@/views/family/Index.vue'
import ShowArchive from '@/components/documents/ShowArchive.vue'
import IndexCourses from '@/views/users/CourseActive.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    GoodTable,
    BOverlay,
    'vue-family': IndexFamily,
    'show-archive': ShowArchive,
    'course-active': IndexCourses,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      modalShowArchive: false,
      getFilePath: '',
      selectRow: null,
      selecction: [],
      modalShow: false,
      infoData: {},
      show: false,
      searchTerm: null,
      UsersData: null,
      modalFamily: false,
      modalShowCourse: false,
      columns: [
        {
          label: 'Acciones',
          field: 'action',
          sortable: false,
          width: '200px',
        },
        {
          label: '#Contrato',
          field: 'n_contrato',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Nombres',
          field: 'first_name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Apellidos',
          field: 'last_name',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Documento',
          field: 'tarjeta_identidad',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Cargo',
          field: 'tipo_cargo',
          tdClass: 'text-center',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Rol',
          field: 'rol',
          tdClass: 'text-center',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            customFilter: false,
            enabled: true,
            placeholder: 'Buscar...',
          },
        },
        {
          label: 'Estado',
          field: 'status_user_',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            customFilter: false,
            placeholder: 'Selecciona un estado',
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [
              { value: 'Desactivado', text: 'Desactivado' },
              { value: 'Activado', text: 'Activado' },
            ],
          },
        },
      ],
    }
  },
  methods: {
    advanceSearch(val) {
      this.searchTerm = val
    },
    closeFamily() {
      this.modalFamily = false
    },
    close() {
      this.modalShow = false
      this.infoData = {}
    },
    close2() {
      this.modalShowArchive = false
      this.getFilePath = ''
    },
    closeCourseActive() {
      this.modalShowCourse = !this.modalShowCourse
    },
    Crear() {
      this.$router.push({
        name: 'apps-users-crear',
      })
    },
    Edit(val) {
      this.$router.push({
        name: 'apps-users-edit',
        params: {
          id: val.id_users,
        },
      })
    },
    activateFamily(val) {
      this.UsersData = val
      this.modalFamily = true
    },
    activeCourseUser(val) {
      this.$refs.CourseActive.selected = []
      this.modalShowCourse = !this.modalShowCourse
      this.UsersData = val
    },
    activeCourseAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$refs.CourseActive.selected = []
        this.modalShowCourse = !this.modalShowCourse
        this.UsersData = this.selecction['0']
      }
    },
    Edit_one() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$router.push({
          name: 'apps-users-edit',
          params: {
            id: this.selecction['0'].id_users,
          },
        })
      }
    },
    ContratoShow(val) {
      this.show = true
      this.$http.post('/v1/contract-pdf/download/', {
        id: val.id_users,
      }).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Actualizado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se actualizo.',
          },
        },
        {
          position: 'top-center',
        })
        const carpeta = 'contract_user'
        let archivo  = res.data.data //eslint-disable-line
        this.getFilePath = `${process.env.VUE_APP_URL_API}/${carpeta}/${archivo}`
        this.modalShowArchive = true
        this.show = false
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
        this.show = false
      })
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
